import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import NavbarStyleFive from "../components/_App/NavbarStyleFive";
import Navbar from "../components/_App/Navbar";
import MainBanner from "../components/PCRepair/MainBanner";
import WhatWeOffer from '../components/DigitalAgency/WhatWeOffer';
import AboutUsContent from '../components/DigitalAgency/AboutUsContent';
import OurServices from '../components/DigitalAgency/OurServices';
import DigitalAgencyFunFacts from '../components/DigitalAgency/DigitalAgencyFunFacts';
import Projects from '../components/DigitalAgency/Projects';
import PricingStyleThree from '../components/PricingPlans/PricingStyleThree';
import FeedbackStyleThree from '../components/Common/FeedbackStyleThree';
import BlogPostStyleTwo from '../components/Common/BlogPostStyleTwo';
import CTAStyleTwo from '../components/Common/CTAStyleTwo';
import Footer from "../components/_App/Footer";

const IndexPage = () => (
    <Layout>
        <SEO title="Yerinde Takas" /> 
        <Navbar />
        <MainBanner />
        {/* <WhatWeOffer /> */}
        <AboutUsContent />
        {/* <OurServices /> */}
        <DigitalAgencyFunFacts />
        {/* <Projects /> */}
        {/* <PricingStyleThree /> */}
        <FeedbackStyleThree />
        {/* <BlogPostStyleTwo /> */}
        <CTAStyleTwo />
        <Footer />
    </Layout>
    // <Layout>
    //     <SEO title="Home" />
    //     <Navbar />
    //     <MainBanner />
    //     <Features />
    //     <ServicesArea />
    //     <OurFeatures />
    //     <Team />
    //     <FunFactsArea />
    //     <RecentWorks />
    //     <PricingStyleOne />
    //     <Feedback />
    //     <Partner />
    //     <BlogPost />
    //     <Footer />
    // </Layout>

)

export default IndexPage;
